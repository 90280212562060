import React, { useState, useContext } from "react";

import axios from "axios";

import { MDBInput, MDBCardTitle, MDBTypography, MDBTooltip, MDBIcon, MDBContainer, MDBRow, MDBCol, MDBCardBody, MDBCard, MDBCardFooter } from "mdb-react-ui-kit";

import { Theme, ThemeContext } from "../components/Theme";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "./App.css";

function ContactForm(props) {
  const { darkMode, genZ } = useContext(ThemeContext);

  const [formValue, setFormValue] = useState({
    referrer: "tombrown.io",
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [sendSucceeded, setSucceded] = useState(undefined);

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    axios({
      method: "POST",
      url: props.sendUrl,
      data: formValue,
    })
      .then((response) => {
        if (response.data.status === "success") {
          resetForm(true);
        } else if (response.data.status === "fail") {
          resetForm(false);
        }
      })
      .catch(function (error) {
        resetForm(false);
      });
  };

  const resetForm = (success) => {
    setSucceded((sendSucceeded) => success);
    setTimeout(function () {
      setSucceded((sendSucceeded) => undefined);
    }, 3000);
    setFormValue({
      ...formValue,
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  };

  return sendSucceeded === undefined ?
    <MDBCard style={darkMode ? { minWidth: "18em", width: "50%", maxWidth: "60rem", backgroundColor: "rgba(0, 0, 0, 0.9)" } : { minWidth: "18em", width: "50%", maxWidth: "60rem", backgroundColor: "rgba(255, 255, 255, 0.9)" }}>
      <form className='' onSubmit={handleSubmit} method='POST'>
        <MDBCardBody cascade>
          <div className='mx-2'>
            <MDBCardTitle className={darkMode ? 'text-light display-6' : 'display-6'}>{genZ === false ? ("Contact Us") : ("Bruh What's Good")}{' '}</MDBCardTitle>
            {darkMode ? <MDBInput value={formValue.name} name='name' onChange={onChange} className='mt-3' label='Name' id='nameInput' type='text' required contrast /> : <MDBInput value={formValue.name} name='name' onChange={onChange} className='mt-3' label='Name' id='nameInput' type='text' required />}
            {darkMode ? <MDBInput value={formValue.email} name='email' onChange={onChange} className='mt-3' label='Email Address' id='emailInput' type='email' required contrast /> : <MDBInput value={formValue.email} name='email' onChange={onChange} className='mt-3' label='Email Address' id='emailInput' type='email' required />}
            {darkMode ? <MDBInput value={formValue.subject} name='subject' onChange={onChange} className='mt-3' label='Subject' id='subjectInput' type='text' required contrast /> : <MDBInput value={formValue.subject} name='subject' onChange={onChange} className='mt-3' label='Subject' id='subjectInput' type='text' required />}
            {darkMode ? <MDBInput value={formValue.message} name='message' onChange={onChange} className='mt-3' label='Message' id='messageInput' textarea rows={6} required contrast /> : <MDBInput value={formValue.message} name='message' onChange={onChange} className='mt-3' label='Message' id='messageInput' textarea rows={6} required />}
          </div>
        </MDBCardBody>
        <MDBCardFooter className='mt-1 text-center'>
          <MDBTooltip wrapperProps={{ type: 'submit', color: darkMode ? 'dark' : 'primary' }} placement='bottom' title='Send'>
            <MDBIcon fas icon='paper-plane' size='2x' />
          </MDBTooltip>
        </MDBCardFooter>
      </form>
    </MDBCard>
    : sendSucceeded === true ? <MDBTypography note noteColor='success'>Thank you for your message!</MDBTypography> :
      <MDBTypography note noteColor='danger'>Message failed to send - please hmu on LinkedIn</MDBTypography>
}

function ContactPage() {
  var sendUrl = "https://mail.tombrown.io/send";
  if (process.env.NODE_ENV !== "production") {
    sendUrl = "http://localhost:3002/send";
  }

  return (
    <div>
      <Navbar />
      <MDBContainer fluid className="my_content">
        <MDBRow className="pb-5 align-items-center my_content">
          <MDBCol className="pb-5" xs="12" lg="10" xl="8" className="offset-lg-1 offset-xl-2">
            <MDBCol className="d-flex justify-content-center align-items-center">
              <ContactForm sendUrl={sendUrl} />
            </MDBCol>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <Theme>
      <ContactPage />
    </Theme>
  );
}

export default App;
