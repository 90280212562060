import React, { useState } from "react";
import { DarkModeToggle, GenZModeToggle } from "./Theme";
import { MDBNavbarToggler, MDBNavbar, MDBCollapse, MDBNavbarNav, MDBNavbarItem, MDBNavbarLink, MDBNavbarBrand, MDBIcon, MDBContainer } from "mdb-react-ui-kit";

import Logo from "../images/logo192.png";
import IngsocDevLogo from "../images/ingsocdev.png";

function Navbar(props) {
  const [showBasic, setShowBasic] = useState(false);

  return (
    <div>
      <MDBNavbar expand="lg" dark>
        <MDBContainer fluid>
          <MDBNavbarBrand href="/"><img id="logo" src={Logo} height="30" alt="" /></MDBNavbarBrand>

          <MDBNavbarToggler aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => setShowBasic(!showBasic)}>
            <MDBIcon icon="bars" fas />
          </MDBNavbarToggler>

          <MDBCollapse navbar show={showBasic}>
            <MDBNavbarNav className="mr-auto mb-2 mb-lg-0">
              <MDBNavbarItem>
                <MDBNavbarLink href="/">Home</MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href="/contact">Contact</MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink href="https://ingsoc.dev">
                  <img id="logo" src={IngsocDevLogo} style={{ height: "30px", width: "30px" }} className="img-fluid" alt="..." />
                </MDBNavbarLink>
              </MDBNavbarItem>
            </MDBNavbarNav>
          </MDBCollapse>
          <GenZModeToggle />
          <DarkModeToggle />
        </MDBContainer>
      </MDBNavbar>
    </div>
  );
}

function App() {
  return <Navbar />;
}

export default App;
