import React, { useEffect, useContext } from "react";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { ThemeContext } from "./Theme";

function EnableMatomoTracking(fpjsVisitorId) {
  console.log("Visitor Id: " + fpjsVisitorId);

  var _paq = (window._paq = window._paq || []);
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(["setUserId", fpjsVisitorId]);
  _paq.push(["trackPageView"]);
  _paq.push(["enableLinkTracking"]);
  (function () {
    var u = "//matomo.tombrown.io/";
    _paq.push(["setTrackerUrl", u + "matomo.php"]);
    _paq.push(["setSiteId", "1"]);
    var d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    g.async = true;
    g.src = u + "matomo.js";
    s.parentNode.insertBefore(g, s);
  })();
}

function Tracking() {
  const { darkMode, genZ } = useContext(ThemeContext);

  useEffect(() => {
    if (getCookieConsentValue() === "true") {
      EnableMatomoTracking();
    }
  });

  return (
    <div>
      <CookieConsent declineButtonText={genZ === false ? ("Decline")
        : ("Naah, we good 🙅")}

        buttonText={genZ === false ? ("Accept")
          : ("No pro bro 👊")}

        style={darkMode ? { color: '#fefefe', background: 'rgba(0, 0, 0, 0.2)' }
          : { color: '#4f4f4f', background: 'rgba(0, 0, 0, 0.2)' }} location="bottom" expires={999} enableDeclineButton

        onAccept={() => {
          // Setup tracking
          EnableMatomoTracking();
        }}
        onDecline={() => {
          // Clear all cookies
          document.cookie.split(";").forEach(function (c) {
            document.cookie = c
              .replace(/^ +/, "")
              .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
          });
        }}>
        This website uses cookies collect information about your session for analytics purposes.
      </CookieConsent>
    </div>
  );
}

export { Tracking };