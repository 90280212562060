import React, { useContext } from "react";
import { MDBTypography, MDBTooltip, MDBIcon, MDBContainer, MDBRow, MDBCol, MDBCardBody, MDBCard, MDBCardImage, MDBCardText, MDBCardFooter, MDBRipple } from "mdb-react-ui-kit";
import Profile from "../images/profile.jpg";
import { Theme, ThemeContext } from "../components/Theme";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "./App.css";

function Home() {
  const { darkMode, genZ } = useContext(ThemeContext);

  return (
    <div>
      <Navbar />
      <MDBContainer fluid className="my_content">
        <MDBRow className="pb-5 align-items-center my_content">
          <MDBCol className="pb-5 offset-lg-1 offset-xl-2" xs="12" lg="10" xl="8">
            <MDBCol className="d-flex justify-content-center align-items-center">
              <MDBCard className="mt-1" background={darkMode ? "dark" : "light"} style={{ minWidth: "18em", width: "30%", maxWidth: "35rem" }}>
                <MDBRipple className="bg-image hover-overlay shadow-1-strong rounded" rippleTag="div" rippleColor="light">
                  <MDBCardImage className="img-fluid card-img-top profile-image" src={Profile} alt="A handsome young chap" />
                  <a href="#!"><div className="mask" style={{ backgroundColor: "rgba(251, 251, 251, 0.2)" }}></div></a>
                </MDBRipple>
                <MDBCardBody cascade className="text-center">
                  <MDBTypography className={darkMode ? "text-light" : "text-dark"} tag="h4">Tom Brown 🏳️‍🌈👨‍💻</MDBTypography>
                  <p className="font-weight-bold text-primary">Software Engineer</p>
                  {genZ === false ? (
                    <MDBCardText className={darkMode ? "text-light" : ""}>
                      I'm a Software Engineer with 9 years industry experience
                      including 5 years in Space & Defence and 3 years making APIs @ Tesco. Now making a next gen
                      banking platform @{" "}<a href="https://mettle.co.uk">Mettle</a> and run{" "}
                      <a href="https://ingsoc.dev">IngsocDev</a>. I also work as founder & part-time CTO for <a href="https://gethireable.com">Hireable</a>{" "}
                      a startup leveraging AI to change the employment landscape for good.
                    </MDBCardText>
                  ) : (
                    <MDBCardText className={darkMode ? "text-light" : ""}>
                      👋 Eyo, I’m a Software Engineer with 9 yrs xp 🔥,
                      I got 5 years in Space & Defence 🚀 and 3 years slaying APIs @ Tesco 🛒. 
                      Now, I’m vibing at <a href="https://mettle.co.uk">Mettle</a> building next-gen banking 💳 and running <a href="https://ingsoc.dev">IngsocDev</a> 👁️ like a boss 🙌. 
                      I’m also the founder & part-time CTO @  <a href="https://gethireable.com">Hireable</a>, where we’re using AI to shake up the job market for good 🤖💼✨.
                    </MDBCardText>
                  )}
                </MDBCardBody>

                <MDBCardFooter className="text-center">
                  <MDBTooltip wrapperProps={{ href: "https://www.linkedin.com/in/tomthehuman/", className: "mx-2", color: darkMode ? "dark" : "primary", }} placement="bottom" title="LinkedIn">
                    <MDBIcon fab icon="linkedin" size="lg" />
                  </MDBTooltip>
                  <MDBTooltip wrapperProps={{ href: "https://gitlab.tombrown.io/public-projects", className: "mx-2", color: darkMode ? "dark" : "primary", }} placement="bottom" title="Gitlab">
                    <MDBIcon fab icon="gitlab" size="lg" />
                  </MDBTooltip>
                  <MDBTooltip wrapperProps={{ href: "http://thatdesignkid.wordpress.com", className: "mx-2", color: darkMode ? "dark" : "primary", }} placement="bottom" title="Wordpress">
                    <MDBIcon fab icon="wordpress-simple" size="lg" />
                  </MDBTooltip>
                </MDBCardFooter>
              </MDBCard>
            </MDBCol>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <Theme>
      <Home themeContext={ThemeContext} />
    </Theme>
  );
}

export default App;
